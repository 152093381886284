<script lang="ts">

    export let animate = true;
    export let icon_class_override;
    
  </script>
  
<img src="/logo/swimerize-icon-light-bg.svg" 
    alt="logo" 
    class="{icon_class_override}" 
    class:animate-rubber-band={animate}
    style="animation-iteration-count: infinite; animation-duration: 1500ms"/>
    <!-- animate-squeeze animate-rubber-band -->
